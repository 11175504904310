<template>
    
    <v-container fluid>

      <!-- Section Path File/Modul -->
      <v-breadcrumbs 
          :items="pathModul"
          divider="-"
          normal
          class="pathModul"
      ></v-breadcrumbs>

      <!-- :class="$vuetify.breakpoint.mdAndDown? '' : ''" -->

      <v-card :class="$vuetify.breakpoint.mdAndDown? 'mt-10' : 'mt-4 mx-15'" >
        <!--<v-row justify="center">
          <v-card-title>CASE DETAILS</v-card-title>
        </v-row> -->

        <v-card-title class="px-6 card-header white--text justify-center">
          <v-icon class="mr-3 white--text">mdi-text-box-multiple</v-icon> <h3>CASE DETAILS</h3>
        </v-card-title>

        <v-container fluid style="height: calc(100vh - 330px);overflow-y:auto;overflow-x:hidden;">

          <!------ CASE Details ------>
          <v-row :class="$vuetify.breakpoint.mdAndDown? 'px-4 pt-4' : 'px-10 pt-4 pb-0'" >
            
            <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '4'">
              <v-subheader class="black--text">Case ID** :</v-subheader>
                    
                <v-text-field
                v-model="caseID"
                placeholder="e.g: ISR/002/18"
                outlined 
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '4'">
              <v-subheader class="black--text">Case Title** :</v-subheader>

              <v-text-field
                v-model="caseTitle"
                outlined 
                dense 
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '4'">
              <v-subheader class="black--text">Case Name** :</v-subheader>

              <v-text-field
                v-model="caseName"
                outlined 
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '3'">
              <!--<v-subheader class="black--text">Start Date** :</v-subheader>-->
                    
              <v-menu
                v-model="menuStartDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    prefix="From** :"
                    prepend-inner-icon="mdi-calendar-start"
                    outlined 
                    v-bind="attrs"
                    v-on="on"
                    dense
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="menuStartDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '3'">
              <!--<v-subheader class="black--text">End Date** :</v-subheader>-->
                    
              <v-menu
                v-model="menuEndDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    prefix="To** :"
                    prepend-inner-icon="mdi-calendar-end"
                    outlined 
                    v-bind="attrs"
                    v-on="on"
                    dense
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="menuEndDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '3'">
              <!--<v-subheader class="black--text">Latitude**  :</v-subheader>-->

              <v-text-field
                v-model="latitude"
                prefix="Latitude**  :"
                outlined 
                dense 
                hide-details
                append-icon="mdi-latitude"
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '3'">
              <!--<v-subheader class="black--text">Longitude** : </v-subheader>-->

              <v-text-field
                v-model="longitude"
                prefix="Longitude**  :"
                outlined 
                dense
                hide-details
                append-icon="mdi-longitude"
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '4'">
              <!--<v-subheader class="black--text">Basin :</v-subheader>-->

              <v-text-field
                v-model="basin"
                prefix="Basin:"
                outlined 
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '4'">
              <!--<v-subheader class="black--text">River :</v-subheader>-->

              <v-text-field
                v-model="river"
                prefix="River :"
                outlined 
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '4'">
              <!--<v-subheader class="black--text">State** :</v-subheader>

              <v-text-field
                v-model="state"
                outlined
                dense
              ></v-text-field>-->

              <v-select
                v-model="state"
                prefix="State** :"
                :items="listState"
                outlined 
                dense
              ></v-select>
            </v-col>

          </v-row>

          <!------ Sampling Point ------>
          <v-row :class="$vuetify.breakpoint.mdAndDown? 'px-5 pb-5' : 'px-10 pt-4'" >
            
            <v-expansion-panels v-model="sampling_point">
              <v-expansion-panel
                v-for="(item,i) in samplingpoint_loop"
                :key="i"
              >
                <v-expansion-panel-header style="background-color: #0081BA" class="white--text expansion-header" >
                  <v-row justify="center">
                    <v-btn
                      x-small
                      text
                      dark
                      style="position: absolute;left: 2px;"
                      @click="addSamplingPoint()"
                    >
                      <v-icon>
                        mdi-plus
                      </v-icon>
                    </v-btn>

                    <v-btn
                      x-small
                      text
                      dark
                      style="position: absolute;left: 50px;"
                      @click="removeSamplingPoint(i)"
                    >
                      <v-icon>
                        mdi-minus
                      </v-icon>
                    </v-btn>

                    <v-icon class="mr-2 white--text" small v-if="$vuetify.breakpoint.mdAndUp">mdi-map-marker</v-icon> <h4>Sampling Point {{ i+1 }}</h4>
                  </v-row>
                </v-expansion-panel-header>

                <v-expansion-panel-content :class="$vuetify.breakpoint.mdAndDown? '' : 'pa-4'">
                  <v-row :class="$vuetify.breakpoint.mdAndDown? '' : 'px-10'">

                    <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '4'">
                      <v-subheader class="black--text">Point ID** :</v-subheader>
                    
                      <v-text-field
                      v-model="item.sampling_pointID"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '4'">
                      <v-subheader class="black--text">State** :</v-subheader>
                    
                      <!--<v-text-field
                        v-model="item.sampling_state"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>-->

                      <v-select
                        v-model="item.sampling_state"
                        :items="listState"
                        outlined 
                        dense
                        hide-details
                      ></v-select>
                    </v-col>

                    <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '4'">
                      <v-subheader class="black--text">River :</v-subheader>
                    
                      <v-text-field
                        v-model="item.sampling_river"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '4'">
                      <v-subheader class="black--text">Latitude** :</v-subheader>
                    
                      <v-text-field
                        v-model="item.sampling_latitude"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '4'">
                      <v-subheader class="black--text">Longitude** :</v-subheader>
                    
                      <v-text-field
                        v-model="item.sampling_longitude"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    

                    <v-col cols="12" style="white-space: nowrap;" :class="$vuetify.breakpoint.mdAndDown? '' : 'd-flex'">
                      <v-subheader class="black--text">Location Description** :</v-subheader>

                      <v-textarea
                        v-model="item.sampling_locationdescription"
                        outlined
                        hide-details
                      ></v-textarea>
                    </v-col>

                    <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '4'">
                      <v-subheader class="black--text">Sampling Matrix** :</v-subheader>
                    
                      <v-text-field
                        v-model="item.sampling_samplingmatrix"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" style="white-space: nowrap;" :cols="$vuetify.breakpoint.mdAndDown? '12' : '4'">
                      <v-subheader class="black--text">Picture** :</v-subheader>
                    
                      <v-file-input
                        small-chips
                        multiple
                        outlined
                        dense
                        hide-details
                      ></v-file-input>
                    </v-col>

                  </v-row>

                  <v-divider class="mt-4"></v-divider>

                  <!-------- Frequency -------->

                  <v-row 
                  :class="$vuetify.breakpoint.mdAndDown? '12 d-flex pt-4' : 'px-10 d-flex pt-4'"
                  v-for="(item,j) in freq_loop" :key="j"
                  >

                    <v-col cols="12" mb-0 pb-0>
                      <v-subheader class="black--text">
                        <v-icon class="mr-2">mdi-bell-circle</v-icon>Frequency {{ j+1 }}
                      </v-subheader>
                    </v-col>

                    <v-col class="d-flex" :cols="$vuetify.breakpoint.mdAndDown? '12' : '4'">
                      <v-subheader class="black--text">Date** :</v-subheader>

                      <v-menu
                        v-model="item.menuFreqDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="item.freq_date"
                            prepend-icon="mdi-calendar"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            dense
                            hide-details
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="item.freq_date"
                          @input="item.menuFreqDate = false"
                        ></v-date-picker>
                      </v-menu>

                    </v-col>

                    <v-col :cols="$vuetify.breakpoint.mdAndDown? '6' : '2'">
                      <v-select
                        v-model="item.freq_hours"
                        :items="hourlist"
                        label="Hours**"
                        outlined
                        dense
                        hide-details
                      ></v-select>
                    </v-col>

                    <v-col :cols="$vuetify.breakpoint.mdAndDown? '6' : '2'">
                      <v-select
                        v-model="item.freq_minutes"
                        :items="minutelist"
                        label="Minutes**"
                        outlined
                        dense
                        hide-details
                      ></v-select>
                    </v-col>

                    <v-col :cols="$vuetify.breakpoint.mdAndDown? '12' : '4'" :class="$vuetify.breakpoint.mdAndDown? '12' : 'd-flex'">
                      <!--<v-subheader class="black--text">Frequency Description :</v-subheader>-->

                      <v-text-field
                        v-model="item.freq_description"
                        label="Frequency Description :"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>

                  </v-row>

                  <!------ Add/Remove Frequency ------>
                  <v-row>
                    <v-spacer></v-spacer>

                    <v-tooltip bottom v-if="freq_loop>1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          class="mr-2"
                          color="error"
                          v-bind="attrs"
                          v-on="on"
                          @click="removeFrequency()"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Remove Frequency</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          color="button" class="white--text"
                          v-bind="attrs"
                          v-on="on"
                          @click="addFrequency()"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Add Frequency</span>
                    </v-tooltip>

                  </v-row>
                  
                </v-expansion-panel-content>

              </v-expansion-panel>
            </v-expansion-panels>

          </v-row>

          <!------ File Input ------>
          <v-row justify="center" :class="$vuetify.breakpoint.mdAndDown? 'px-4' : 'px-16 mx-16 pt-8'">
            <v-col :cols="$vuetify.breakpoint.mdAndDown? '6' : ''">
              <v-file-input
                label="Report"
                small-chips
                multiple
                outlined
                dense
                hide-details
              ></v-file-input>
            </v-col>

            <v-col :cols="$vuetify.breakpoint.mdAndDown? '6' : ''">
              <v-file-input
                label="COC"
                small-chips
                multiple
                outlined
                dense
                hide-details
              ></v-file-input>
            </v-col>

            <v-col :cols="$vuetify.breakpoint.mdAndDown? '6' : ''">
              <v-file-input
                label="Executive Summary"
                small-chips
                multiple
                outlined
                dense
                hide-details
              ></v-file-input>
            </v-col>

            <v-col :cols="$vuetify.breakpoint.mdAndDown? '6' : ''">
              <v-file-input
                label="COA"
                small-chips
                multiple
                outlined
                dense
                hide-details
              ></v-file-input>
            </v-col>

            <v-col :cols="$vuetify.breakpoint.mdAndDown? '6' : ''">
              <v-file-input
                label="Sampling Data"
                small-chips
                multiple
                outlined
                dense
                hide-details
              ></v-file-input>
            </v-col>

          </v-row>

          <!------ Remarks ------>
          <v-row :class="$vuetify.breakpoint.mdAndDown? 'px-4' : 'px-16 mx-16'">
            <v-col>
              <v-textarea
                outlined
                v-model="remarks"
                label="Remarks :"
                placeholder="Remarks"
              ></v-textarea>
            </v-col>
          </v-row>

        </v-container>

        <v-divider></v-divider>

        <v-row justify="center" class="mt-6 pb-6">
          <v-btn
            color="button"
            class="mr-4 white--text"
          >
            RESET
          </v-btn>
          <v-btn
            color="button"  class="white--text"
            @click="preview()"
          >
            PREVIEW
          </v-btn>
        </v-row>

      </v-card>


    </v-container>

    

</template>


<script>

import axios from 'axios';
import Papa from "papaparse";

export default {

    
    data: () => ({

      caseID: null,
      caseTitle: null,
      longitude: null,
      basin: null,
      caseName: null,
      latitude: null,
      river: null,
      state: null,
      startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menuStartDate: false,
      menuEndDate: false,

      // samplingpoint_loop: 1,
      samplingpoint_loop: ['1'],

      remarks: null,

      sampling_point:null,
      sampling_pointID: null,
      sampling_latitude: null,
      sampling_longitude: null,
      sampling_river: null,
      sampling_state: null,
      sampling_locationdescription: null,
      sampling_samplingmatrix: null,
      
      freq_hours: null,
      freq_minutes: null,
      freq_description: null,
      freq_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menuFreqDate: false,

      hourlist: ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
      minutelist: ['01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00',
                  '16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','24:00','25:00','26:00','27:00','28:00','29:00','30:00',
                  '31:00','32:00','33:00','34:00','35:00','36:00','37:00','38:00','39:00','40:00','41:00','42:00','43:00','44:00','45:00',
                  '46:00','47:00','48:00','49:00','50:00','51:00','52:00','53:00','54:00','55:00','56:00','57:00','58:00','59:00','60:00',
                  ],
      listState: ['PERLIS', 'KEDAH', 'PULAU PINANG', 'PERAK', 'SELANGOR', 'WP KUALA LUMPUR', 'NEGERI SEMBILAN', 'MELAKA', 'JOHOR', 'PAHANG', 'TERANGGANU', 'KELANTAN', 'SABAH', 'SARAWAK'],

      pathModul: [
          {
              text: 'Home',
              disabled: false,
              href: '/Mapviewer'
          },
          {
              text: 'Report - Investigate Study Data Entry',
              disabled: true,
              href: '/Report/RawData',
          },
      ],

      freq_loop : 1,

       
     

        
    }),

    methods: {

      preview(){
        console.log(this.longitude);
      },

      addSamplingPoint(){
        // this.samplingpoint_loop = this.samplingpoint_loop + 1;
        // this.sampling_point = null;

        this.samplingpoint_loop.push('1');
        this.sampling_point = null;
      },

      removeSamplingPoint(i){

        // if(this.samplingpoint_loop > 1){
        //   this.samplingpoint_loop = this.samplingpoint_loop - 1;
        // }

        // this.sampling_point = null;

        if(this.samplingpoint_loop.length > 1){
          this.samplingpoint_loop.splice(i, 1);
          this.active = null;
        }

        
      },

      addFrequency(){
        this.freq_loop = this.freq_loop + 1;
      },

      removeFrequency(){
        if(this.freq_loop > 1){
          this.freq_loop = this.freq_loop - 1;
        }
      },

      convert12Hours(time) {
          var H = +time.substr(0, 2);
          var h = H % 12 || 12;
          var ampm = (H < 12 || H === 24) ? "AM" : "PM";
          time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
          return time;
      },

      convertDateFormat(date){
          var dd = date.split("-")[2];
          var mm = date.split("-")[1];
          var yyyy = date.split("-")[0];
          date = dd + "/" + mm + "/" + yyyy;
          return date;
      },

      // Anything need to load first refresh
      load(){

      },

      getListStation(){
        axios.get('https://apis.spatialworks.com.my/sarawak/cms/stations', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            // console.log(response.data);
            this.itemStation = [];

            for (let i = 0; i < response.data.length; i++) {
              this.itemStation.push(response.data[i].stationId + " - " + response.data[i].location);
            }
            
        })
        .catch(error => {
            console.log(error);
        })
      },

     
     

      // Get value from Selected Station
      selectValueStation: function(e) {
        this.valStation = e.split(' - ')[0];
        // console.log(this.valStation);
      },


      // Get Data from API Params
      loadDataBody(){

        this.firstLoadtbody_rawData = [];
        this.tbody_rawData = [];

        axios.get('https://apis.spatialworks.com.my/sarawak/cms/rawdata?startdt='+ this.dateFrom +'&enddt=' + this.dateTo + '&stationid=all&raw=1',{
              headers: {
                  'Authorization': 'Bearer ' + this.$store.getters.user.token,
              }
          })
          .then((response) => {
            
              this.GlobalDataBody = response.data;
              let keyData = Object.keys(response.data[0])
              for (let i = 0; i < response.data.length; i++) {
                
                response.data[i]["DATETIME"] = this.convertDateFormat(response.data[i]["DATETIME"].split(" ")[0]) 
                + "<br>"
                + this.convert12Hours(response.data[i]["DATETIME"].split(" ")[1]);
              }

              for (let x = 0; x < response.data.length; x++) {
                if (response.data[x]["station_a"] === this.valStation) {
                  // console.log(response.data[x]);
                  this.firstLoadtbody_rawData.push(response.data[x])
                  
                  // this.GlobalDataBody.push(response.data[x]);
                }
              }

              if (this.firstLoadtbody_rawData.length != 0) {
                let myInterval = setInterval(() => {
                    this.loadDataQAQC();
                    clearInterval(myInterval);
                }, 1000);
              }
              else{
                this.loadingTable = false;
                this.loadingText = "";
              }

          })
          .catch(error => {
              console.log(error);
          })
      },

      // Section Table Function
      

      // Customize background color for column table for result WQI status
      getColor(wqi) {
        if (wqi > 92.7){
          return '#3486FA'
        }
        else if (wqi > 76.5 && wqi <= 92.7) {
          return '#7AE666'
        }
        else if (wqi >= 50 && wqi <= 76.5) {
          return '#FFFF10'
        }
        else if (wqi >= 31.0 && wqi <= 51.9) {
          return '#FFAB3D'
        }
        else if(wqi >= 1 && wqi <= 30.9){
          return '#FB401D'
        }
        else{
          return 'white'
        }
      },

      // Customize font color for column table for result WQI status
      getFontColor(wqi){
        if (wqi > 92.7){
          return 'white--text'
        }
        else if (wqi > 76.5 && wqi <= 92.7) {
          return 'black--text'
        }
        else if (wqi >= 50 && wqi <= 76.5) {
          return 'black--text'
        }
        else if (wqi >= 31.0 && wqi <= 51.9) {
          return 'white--text'
        }
        else if(wqi >= 1 && wqi <= 30.9){
          return 'white--text'
        }
        else{
          return 'black--text'
        }
      },

      exportReport(){

          let valueHeader = [];
          for (let i = 0; i < this.thead_rawData.length; i++) {
              valueHeader.push(this.thead_rawData[i].value);
          }

          let keys = valueHeader,
          result = this.tbody_rawData.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));

          var blob = new Blob([Papa.unparse(this.specialExcel(this.thead_rawData,result))], { type: 'text/csv;charset=utf-8;' });

          var link = document.createElement("a");

          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", 'Raw Data For ('+ this.valStation +') From '+ this.convertDateFormat(this.dateFrom) +' To '+ this.convertDateFormat(this.dateTo) +' .csv');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        
      },

      testAPI(){

        axios.get('https://run.mocky.io/v3/98873f4d-ebf7-44ff-9565-4fa2674af35c', {
            headers: {
                'Authorization': 'Bearer ' + this.$store.getters.user.token,
            }
        })
        .then((response) => {
            console.log(response.data);
            
        })
        .catch(error => {
            console.log(error);
        })
      },
      
    },
    mounted() {
    }

}
</script>



<style lang="scss">

    @import '~scss/main';

    .freq_table > .v-data-table__wrapper > table > tbody > tr > td{
      border-bottom: none !important;
    } 

    /* Footer Report */
    .one-text-footer{
      margin: 0px 10px;
    }

    .modified_input > .v-input__control > .v-input__slot > fieldset{
      height: 35px !important;
    }

    .expansion-header > .v-expansion-panel-header__icon > i{
      color: white !important;
    }


</style>